






















































































































































































































































































































































































































































import DecimalJs from 'decimal.js';
import { Component, Vue } from 'vue-property-decorator';
import type { RawLocation } from 'vue-router';
import { NumberInput, SvgIcon } from '@/components/custom';
import i18n from '@/services/i18n';
import { CollapsiblePane, ComponentBase, FlatButton } from '../components';
import { BudgetVariants, Comp, Dict, Report2, Version } from '../types';



// region Типы
interface ReportInfoAndContent {
    info: Report2;
    content: Report2.Content;
}

interface DistColumnGroup {
    base: Report2.Content.ColumnItem.Column;
    slaves: Map<number, Report2.Content.ColumnItem.Column>;
}

interface DistColumnGroupForRebuilding extends DistColumnGroup {
    addedSlaves: Map<number, Report2.Content.ColumnItem.Column>;
    removedSlaves: Map<number, Report2.Content.ColumnItem.Column>;
}

interface SheetSubprogram {
    reportId: number
    subprogram: number;
    sheetNumber: number;
}
// endregion


const translates = {
    budgetSubprograms: {
        get showModal(): string {
            return i18n.choose(
                'Бюджетные подпрограммы',
                'Бюджетные подпрограммы (каз)',
                'Budget subprograms',
            );
        },
         subprogramText(ppr: number): string {
            return i18n.choose(
                `Подпрограммы #${ppr}`,
                `Подпрограммы #${ppr} (каз)`,
                `Subprograms #${ppr}`,
            );
        },
    },
    dateFormats: {
        get fullDate(): Intl.DateTimeFormat {
            return new Intl.DateTimeFormat(i18n.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
        },
        get fullDateTime(): Intl.DateTimeFormat {
            return new Intl.DateTimeFormat(i18n.locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
        },
    },
    error: {
        get cannotLoadBudgetSubprograms(): string {
            return i18n.choose(
                'Error while loading budget subprograms',
                'Ошибка при загрузке бюджетный подпрограмм (каз)',
                'Ошибка при загрузке бюджетный подпрограмм',
            );
        },
        cannotLoadReportContent(id: number): string {
            return i18n.choose(
                () => (`Cannot load content of report with ID #${id}`),
                () => (`Не удалось загрузить содержимое отчета с ID #${id} (каз)`),
                () => (`Не удалось загрузить содержимое отчета с ID #${id}`),
            )();
        },
        get cannotSaveReport(): string {
            return i18n.choose(
                'Не удалось сохранить отчет',
                'Не удалось сохранить отчет',
                'Cannot save report',
            );
        },
    },
    infoMessage: {
      emptySubprogramLinks(id: number): string {
         return i18n.choose(
             `Для отчета №${id} не выполнено распределение по бюджетным подпрограммам`,
             `Есеп №${id} бойынша бюджеттік ішкі бағдарламаларға бөлу орындалмады`,
             `Allocation to budget subprograms has not been completed for report No. ${id}`,
         );

     },
        get infoWarning(): string {
            return i18n.choose(
                `Внимание`,
                'Назар аударыңыз',
                'Notice',
            );
        },
        get subprogramButton(): string {
            return i18n.choose(
                `Отчеты по подпрограммам`,
                'Бағдарламалық есептерді қалыптастыру',
                'Subprogram Reports',
            );
        }
    },
    reportInfoTable: {
        fields: {
            abp: {
                getValue(source: Report2): string {
                    let result = String(source.abp);
                    while (result.length < 3) {
                        result = '0' + result;
                    }
                    return result;
                },
                get title(): string {
                    return i18n.choose(
                        'АБП',
                        'АБП',
                        'BPA',
                    );
                },
            },
            budgetProgram: {
                getValue(source: Report2): string {
                    let result = String(source.budgetProgram);
                    while (result.length < 3) {
                        result = '0' + result;
                    }
                    return result;
                },
                get title(): string {
                    return i18n.choose(
                        'Бюджетная программа',
                        'Бюджетная программа',
                        'Budget program',
                    );
                }
            },
            budgetVariant: {
                get title(): string {
                    return i18n.choose(
                        'Вариант бюджета',
                        'Вариант бюджета',
                        'Budget variant',
                    );
                },
                getValue(source: Report2): string {
                    const obj = source.budgetVariantObject;

                    if (obj === null) {
                        return '';
                    }

                    const name = i18n.choose(
                        () => (obj.nameRu),
                        () => (obj.nameKk ?? obj.nameRu),
                        () => (obj.nameEn ?? obj.nameRu),
                    )();

                    return `[${obj.variantUuid}] ${name}`;
                },
            },
            creationMoment: {
                getValue(source: Report2): string {
                    return translates.dateFormats.fullDateTime.format(new Date(source.creationMoment));
                },
                get title(): string {
                    return i18n.choose(
                        'Дата и время создания',
                        'Дата и время создания',
                        'Creation date and time',
                    );
                },
            },
            form: {
                get title(): string {
                    return i18n.choose(
                        'Отчет',
                        'Отчет',
                        'Report',
                    );
                },
            },
            functionalGroup: {
                getValue(source: Report2): string {
                    let result = String(source.funcGroup);
                    while (result.length < 3) {
                        result = '0' + result;
                    }
                    return result;
                },
                get title(): string {
                    return i18n.choose(
                        'Функциональная группа',
                        'Функциональная группа',
                        'Functional group',
                    );
                },
            },
            functionalSubgroup: {
                getValue(source: Report2): string {
                    let result = String(source.funcSubgroup);
                    while (result.length < 3) {
                        result = '0' + result;
                    }
                    return result;
                },
                get title(): string {
                    return i18n.choose(
                        'Функциональная подгруппа',
                        'Функциональная подгруппа',
                        'Functional subgroup',
                    );
                },
            },
            id: {
                get title(): string {
                    return 'ID';
                },
            },
            organization: {
                getValue(source: Report2): string {
                    const org = source.org;
                    if (org === null) {
                        return '';
                    }

                    const type = org.type;
                    switch (org.type) {
                        case 'GU':
                            return i18n.choose(
                                () => (`ГУ ${org.gu.code} - ${org.gu.nameRu}`),
                                () => (`ГУ ${org.gu.code} - ${org.gu.nameKk ?? org.gu.nameRu}`),
                                () => (`GA ${org.gu.code} - ${org.gu.nameEn ?? org.gu.nameRu}`),
                            )();
                        case 'KGKP':
                            return i18n.choose(
                                () => (`КГКП ${org.kgkp.bin} - ${org.kgkp.nameRu}`),
                                () => (`КГКП ${org.kgkp.bin} - ${org.kgkp.nameKk ?? org.kgkp.nameRu}`),
                                () => (`КГКП ${org.kgkp.bin} - ${org.kgkp.nameRu}`),
                            )();
                        default:
                            console.warn(`Found unknown org type "${type}"`);
                            return '';
                    }
                },
                get title(): string {
                    return i18n.choose(
                        'Организация',
                        'Организация',
                        'Organization',
                    );
                }
            },
            planningPeriod: {
                getValue(source: Report2): string {
                    const variant = source.budgetVariantObject;
                    const year = variant?.year ?? null
                    if (year === null) {
                        return '';
                    }

                    return `${year} - ${year + 2}`;
                },
                get title(): string {
                    return i18n.choose(
                        'Плановый период',
                        'Плановый период',
                        'Planned date range',
                    );
                },
            },
            regionCode: {
                get title(): string {
                    return i18n.choose(
                        'Код региона',
                        'Код региона',
                        'Region code',
                    );
                },
            },
            regionName: {
                getValue(source: Report2): string {
                    const obj = source.regionObject;
                    if (obj === null) {
                        return '';
                    }

                    return i18n.choose(
                        () => (obj.nameRu ?? ''),
                        () => (obj.nameKk ?? obj.nameRu ?? ''),
                        () => (obj.nameRu ?? ''),
                    )();
                },
                get title(): string {
                    return i18n.choose(
                        'Название региона',
                        'Название региона',
                        'Region name',
                    );
                },
            },
            reportDate: {
                getValue(source: Report2): string {
                    const obj = source.budgetVariantObject;
                    if (obj === null) {
                        return '';
                    }
                    const dateStart = obj.dateStart;

                    if (dateStart === null) {
                        return '';
                    }

                    return translates.dateFormats.fullDate.format(new Date(dateStart));
                },
                get title(): string {
                    return i18n.choose(
                        'Дата отчета',
                        'Дата отчета',
                        'Report date',
                    );
                },
            },
            specificity: {
                getValue(source: Report2) {
                    let result = String(source.specificity);
                    while (result.length < 3) {
                        result = '0' + result;
                    }
                    return result;
                },
                get title(): string {
                    return i18n.choose(
                        'Специфика',
                        'Специфика',
                        'Specificity',
                    );
                },
            },
            version: {
                getValue(source: Report2): string {
                    const obj = source.version;
                    if (obj === null) {
                        return '';
                    }

                    const title = obj.title;
                    if (title === null) {
                        return `#${obj.id}`;
                    } else {
                        return `#${obj.id} ${title}`;
                    }
                },
                get title(): string {
                    return i18n.choose(
                        'Версия ШР',
                        'Версия ШР',
                        'ST version',
                    );
                },
            },
        },
        get title(): string {
            return i18n.choose(
                'Информация об отчете',
                'Информация об отчете',
                'Report information',
            );
        },
    },
    get reportSaved(): string {
        return i18n.choose(
            'Отчет сохранен',
            'Отчет сохранен',
            'Report saved',
        );
    },
    get saveButton(): string {
        return i18n.choose(
            'Сохранить',
            'Сохранить',
            'Save',
        );
    },
    totalRow: {
        get title(): string {
            return i18n.choose(
                'Итого',
                'Итого (каз)',
                'Total',
            );
        },
    },
    get transferRemainder(): string {
        return i18n.choose(
            'Перенести остаток',
            'Перенести остаток',
            'Transfer remainder',
        );
    },
};


// region Утилиты - содержимое отчета
const objectsEqual = <T, K extends keyof T = keyof T>(objects: Array<T>, keys: Array<K>): boolean => {
    if (objects.length < 2) {
        return true;
    }

    const first = objects[0] as unknown as Record<K, unknown>;
    for (let i = 1; i < objects.length; i++) {
        const object = objects[i] as unknown as Record<K, unknown>;
        for (const key of keys) {
            if (first[key] !== object[key]) {
                return false;
            }
        }
    }

    return true;
};


const copyReportContent = (source: Report2.Content): Report2.Content => {
    return {
        additionalData: source.additionalData,
        sheet1: copyReportSheet(source.sheet1),
        sheet2: copyReportSheet(source.sheet2),
        sheet3: copyReportSheet(source.sheet3),
    }
};

const reportContentsEqual = (item1: Report2.Content, item2: Report2.Content): boolean => {
    return (
        (item1.additionalData === item2.additionalData)
        &&
        reportSheetEqual(item1.sheet1, item2.sheet1)
        &&
        reportSheetEqual(item1.sheet2, item2.sheet2)
        &&
        reportSheetEqual(item1.sheet3, item2.sheet3)
    );
};


const copyReportSheet = (source: Report2.Content.Sheet): Report2.Content.Sheet => {
    return {
        rootColumnGroups: copyColumnItems(source.rootColumnGroups),
        rows: copyRows(source.rows),
    };
};

const reportSheetEqual = (item1: Report2.Content.Sheet, item2: Report2.Content.Sheet): boolean => {
    return (
        (columnItemListsEqual(item1.rootColumnGroups, item2.rootColumnGroups))
        &&
        (rowListsEqual(item1.rows, item2.rows))
    );
};


const copyColumnItems = <T extends Report2.Content.ColumnItem>(source: Array<T>): Array<T> => {
    return source.map((sourceItem) => {
        return copyColumnItem(sourceItem);
    });
};

const copyColumnItem = <T extends Report2.Content.ColumnItem>(source: T): T => {
    const type = source.type;
    switch (source.type) {
        case 'GROUP':
            return source;
        case 'COLUMN':
            const sourceColumn: Report2.Content.ColumnItem.Column = (source as Report2.Content.ColumnItem.Column);
            const result: Report2.Content.ColumnItem.Column = {
                type: 'COLUMN',
                id: sourceColumn.id,
                orderNumber: sourceColumn.orderNumber,
                name: sourceColumn.name,
                key: sourceColumn.key,
                undistributedKey: sourceColumn.undistributedKey,
                unitName: sourceColumn.unitName,
                aggregation: sourceColumn.aggregation,
                width: sourceColumn.width,
                dataSource: sourceColumn.dataSource,
                colType: sourceColumn.colType,
                subprogDistEditable: sourceColumn.subprogDistEditable,
                detailedReportOnly: sourceColumn.detailedReportOnly,
                removeIfNoData: sourceColumn.removeIfNoData,
                fractionalDigitCount: sourceColumn.fractionalDigitCount,
                budgetSubprogramCode: sourceColumn.budgetSubprogramCode,
                forTotal: sourceColumn.forTotal,
            };
            return (result as unknown as T);
        default:
            throw new Error(`Unknown column item type "${type}"`);
    }
};

const columnItemListsEqual = <T extends Report2.Content.ColumnItem>(list1: Array<T>, list2: Array<T>): boolean => {
    if (list1.length !== list2.length) {
        return false;
    }

    for (let i = 0; i < list1.length; i++) {
        if (!columnItemsEqual(list1[i], list2[i])) {
            return false;
        }
    }

    return true;
};

const columnItemsEqual = <T extends Report2.Content.ColumnItem>(item1: T, item2: T): boolean => {
    const type1 = item1.type;
    const type2 = item2.type;
    if (type1 !== type2) {
        return false;
    }

    switch (item1.type) {
        case "GROUP":
            if (item2.type === 'GROUP') {
                return (item1.id === item2.id);
            } else {
                return false;
            }
        case 'COLUMN':
            if (item2.type === 'COLUMN') {
                const col1 = item1 as Report2.Content.ColumnItem.Column;
                const col2 = item2 as Report2.Content.ColumnItem.Column;
                return objectsEqual([col1, col2], ['id', 'key', 'undistributedKey', 'budgetSubprogramCode']);
            } else {
                return false;
            }
        default:
            throw new Error(`Found unknown column item type "${type1}"`);
    }
};


const copyRows = (source: Array<Report2.Content.Row>): Array<Report2.Content.Row> => {
    return source.map((sourceItem) => {
        return copyRow(sourceItem);
    });
};

const copyRow = (source: Report2.Content.Row): Report2.Content.Row => {
    return {
        orderNumber: source.orderNumber,
        valueMap: copyRowValueMap(source.valueMap),
    }
};

const copyRowValueMap = (source: Record<string, Report2.Content.DescribedValue>): Record<string, Report2.Content.DescribedValue> => {
    const result: Record<string, Report2.Content.DescribedValue> = {};

    Object
        .getOwnPropertyNames(source)
        .filter((key) => (key !== '__ob__'))
        .forEach((key) => {
            result[key] = copyDescribedValue(source[key]);
        });

    return result;
};

const rowListsEqual = (item1: Array<Report2.Content.Row>, item2: Array<Report2.Content.Row>): boolean => {
    if (item1.length !== item2.length) {
        return false;
    }

    for (let i = 0; i < item1.length; i++) {
        if (!rowsEqual(item1[i], item2[i])) {
            return false;
        }
    }

    return true;
};

const rowsEqual = (item1: Report2.Content.Row, item2: Report2.Content.Row): boolean => {
    if (item1.orderNumber !== item2.orderNumber) {
        return false;
    }

    const keys = Object.getOwnPropertyNames(item1.valueMap).filter((key) => (key !== '__ob__'));
    for (const key of keys) {
        const value2 = item2.valueMap[key];
        // noinspection JSIncompatibleTypesComparison
        if (value2 === undefined) {
            return false;
        }

        if (!describedValuesEqual(item1.valueMap[key], value2)) {
            return false;
        }
    }

    return true;
};


const copyDescribedValue = (source: Report2.Content.DescribedValue): Report2.Content.DescribedValue => {
    return {
        value: source.value,
        descriptionListMap: source.descriptionListMap,
    };
};

const describedValuesEqual = (item1: Report2.Content.DescribedValue, item2: Report2.Content.DescribedValue): boolean => {
    return (item1.value === item2.value);
};
// endregion


// region Утилиты - листы
const sheetHasErrors = (sheet: Report2.Content.Sheet, columnTreeData?: ColumnTreeData | null): boolean => {
    let actualColumnTreeData: ColumnTreeData;
    if ((columnTreeData === undefined) || (columnTreeData === null)) {
        actualColumnTreeData = defineColumnTreeData(sheet.rootColumnGroups);
    } else {
        actualColumnTreeData = columnTreeData;
    }

    const { columns } = actualColumnTreeData;

    const distColumnGroups = [...defineDistColumnGroupMap(columns).values()];

    for (const row of sheet.rows) {
        if (rowHasError(distColumnGroups, row)) {
            return true;
        }
    }
    return false;
};
// endregion


// region Утилиты - колонки
interface ColumnTreeItem {
    parent: ColumnTreeItem | null;
    item: Report2.Content.ColumnItem;
    hidden: boolean;
    level: number;
    colSpan: number;
    rowSpan: number;
    children: Array<ColumnTreeItem>;
}

interface ColumnTreeData {
    rows: Array<Array<ColumnTreeItem>>;
    columns: Array<Report2.Content.ColumnItem.Column>;
}

const convertColumnItemsToTreeItems = (source: Array<Report2.Content.ColumnItem>, parent?: ColumnTreeItem): Array<ColumnTreeItem> => {
    const preparedParent = parent ?? null;
    const level = (parent?.level ?? -1) + 1;
    return source.map((sourceItem) => {
        const resultItem: ColumnTreeItem = {
            parent: preparedParent,
            item: sourceItem,
            hidden: false,
            level,
            colSpan: 1,
            rowSpan: 1,
            children: [],
        };

        if (sourceItem.type === 'GROUP') {
            const sourceChildren = sourceItem.items;
            if (sourceChildren.length > 0) {
                resultItem.children = convertColumnItemsToTreeItems(sourceChildren, resultItem);

                resultItem.colSpan = 0;
                for (const childItem of resultItem.children) {
                    resultItem.colSpan += childItem.colSpan;
                }
            }
        }

        return resultItem;
    });
};

const scanColumnItemsForMaxLevel = (target: { level: number }, items: Array<ColumnTreeItem>): number => {
    if (items.length > 0) {
        if (target.level < items[0].level) {
            target.level = items[0].level;
        }

        for (const item of items) {
            if (item.children.length > 0) {
                scanColumnItemsForMaxLevel(target, item.children);
            }
        }
    }
    return target.level;
};

const fixLevelsOfColumnItems = (items: Array<ColumnTreeItem>) => {
    for (const item of items) {
        const parent = item.parent;
        if (parent === null) {
            item.level = 0;
        } else {
            if (parent.hidden) {
                item.level = parent.level;
            } else {
                item.level = parent.level + 1;
            }
        }

        const sourceItem = item.item;
        if (sourceItem.type === 'GROUP') {
            if (sourceItem.hiddableCell && (item.children.length < 2)) {
                item.hidden = true;
            }
            fixLevelsOfColumnItems(item.children);
        }
    }
};

const fixRowSpansOfColumnItems = (maxLevel: number, items: Array<ColumnTreeItem>) => {
    for (const treeItem of items) {
        const columnItem = treeItem.item;
        if (columnItem.type === 'COLUMN') {
            treeItem.rowSpan = (maxLevel - treeItem.level) + 1;
        } else {
            fixRowSpansOfColumnItems(maxLevel, treeItem.children);
        }
    }
};

const defineColumnTreeRoots = (source: Array<Report2.Content.ColumnItem>): Array<ColumnTreeItem> => {
    const result: Array<ColumnTreeItem> = convertColumnItemsToTreeItems(source);

    fixLevelsOfColumnItems(result);

    const maxLevel = scanColumnItemsForMaxLevel({ level: 0 }, result);
    fixRowSpansOfColumnItems(maxLevel, result);

    return result;
};

const placeColumnItemsIntoTreeData = (target: ColumnTreeData, treeItems: Array<ColumnTreeItem>) => {
    for (const treeItem of treeItems) {
        const requiredRowCount = treeItem.level + 1;
        while (target.rows.length < requiredRowCount) {
            target.rows.push([]);
        }

        const row = target.rows[treeItem.level];
        row.push(treeItem);

        const item = treeItem.item;
        if (item.type === 'COLUMN') {
            target.columns.push(item);
        } else {
            placeColumnItemsIntoTreeData(target, treeItem.children);
        }
    }
};

const defineColumnTreeData = (source: Array<Report2.Content.ColumnItem>): ColumnTreeData => {
    const roots = defineColumnTreeRoots(source);

    const result: ColumnTreeData = { rows: [], columns: [] };
    placeColumnItemsIntoTreeData(result, roots);

    return result;
};

const defineDistColumnGroupMap = (columns: Array<Report2.Content.ColumnItem.Column>): Map<string, DistColumnGroup> => {
    const result = new Map<string, DistColumnGroup>();

    columns.forEach((column) => {
        if (column.subprogDistEditable) {
            if (column.key === column.undistributedKey) {
                const group: DistColumnGroup = {
                    base: column,
                    slaves: new Map(),
                };
                result.set(column.undistributedKey, group);
            } else {
                const budgetSubprogramCode = column.budgetSubprogramCode;
                if (budgetSubprogramCode !== null) {
                    const group = result.get(column.undistributedKey);
                    // noinspection JSIncompatibleTypesComparison
                    if (group !== undefined) {
                        group.slaves.set(budgetSubprogramCode, column);
                    }
                }
            }
        }
    });

    return result;
};
// endregion


// region Утилиты - строки
const getRowDistributionEditableValue = (row: Report2.Content.Row, column: Report2.Content.ColumnItem.Column): number => {
    const describedValue = (row.valueMap[column.key] as unknown as (Report2.Content.DescribedValue | undefined));
    if (describedValue === undefined) {
        return 0;
    } else {
        const value = describedValue.value;
        if (value === null) {
            return 0;
        }
        switch (value.type) {
            case 'STRING':
                return 0;
            case 'NUMBER':
                return value.value;
            default:
                return 0;
        }
    }
};

const getRowDistributableRemainder = (distColumnGroup: DistColumnGroup, row: Report2.Content.Row): number => {
    const describedValue = (row.valueMap[distColumnGroup.base.key] as unknown as (Report2.Content.DescribedValue | undefined));
    if (describedValue !== undefined) {
        const value = describedValue.value;
        if ((value !== null) && (value.type === 'NUMBER')) {
            const column = distColumnGroup.base;
            if (column.subprogDistEditable) {
                let remaining = new DecimalJs(value.value);
                distColumnGroup.slaves.forEach((slaveColumn) => {
                    const slaveValue = getRowDistributionEditableValue(row, slaveColumn);
                    remaining = remaining.minus(slaveValue);
                });
                return remaining.toNumber();
            }
        }
    }

    return 0;
};

const rowHasError = (distColumnGroups: Array<DistColumnGroup>, row: Report2.Content.Row): boolean => {
    for (const distColumnGroup of distColumnGroups) {
        const reminder = getRowDistributableRemainder(distColumnGroup, row);
        if (reminder !== 0) {
            return true;
        }
    }
    return false;
};
// endregion


// region Утилиты - бюджетные подпрограммы
interface BudgetSubprogramWrap {
    code: number;
    item: Dict.EbkFunc | null;
}

const budgetSubprogramWrapSorter = (item1: BudgetSubprogramWrap, item2: BudgetSubprogramWrap): number => {
    return (item1.code - item2.code);
};

const columnSorter = (item1: Report2.Content.ColumnItem, item2: Report2.Content.ColumnItem): number => {
    if ((item1.type === 'COLUMN') && (item2.type === 'COLUMN') && (item1.orderNumber === item2.orderNumber)) {
        const item1Code = (item1.budgetSubprogramCode ?? 0);
        const item2Code = (item2.budgetSubprogramCode ?? 0);
        return item1Code - item2Code;
    } else {
        return item1.orderNumber - item2.orderNumber;
    }
};

const rebuildColumnGroupBySubprogramCodes = (
    codes: Array<number>,
    codeSet: Set<number>,
    target: Report2.Content.ColumnItem.ColumnGroup,
    outputDistColumnGroupMap: Map<string, DistColumnGroupForRebuilding>,
) => {
    const distColumnGroupMap = new Map<string, DistColumnGroupForRebuilding>();

    // Определение групп колонок, определение в группах колонок для удаления
    target.items.forEach((item) => {
        switch (item.type) {
            case 'COLUMN':
                (() => {
                    const column = item;
                    if (column.subprogDistEditable) {
                        const key = column.undistributedKey;
                        if (column.key === key) {
                            const group: DistColumnGroupForRebuilding = {
                                base: column,
                                slaves: new Map(),
                                addedSlaves: new Map(),
                                removedSlaves: new Map(),
                            };
                            outputDistColumnGroupMap.set(key, group);
                            distColumnGroupMap.set(key, group);
                        } else {
                            const budgetSubprogramCode = column.budgetSubprogramCode;
                            if (budgetSubprogramCode !== null) {
                                const group = outputDistColumnGroupMap.get(key);
                                // noinspection JSIncompatibleTypesComparison
                                if (group !== undefined) {
                                    group.slaves.set(budgetSubprogramCode, column);
                                    if (!codeSet.has(budgetSubprogramCode)) {
                                        group.removedSlaves.set(budgetSubprogramCode, column);
                                    }
                                }
                            }
                        }
                    }
                })();
                break;
            case 'GROUP':
                rebuildColumnGroupBySubprogramCodes(codes, codeSet, item, outputDistColumnGroupMap);
                break;
        }
    });

    // Добавление новых колонок в группы
    distColumnGroupMap.forEach((group) => {
        codes.forEach((code) => {
            if (!group.slaves.has(code)) {
                let codeString = String(code);
                while (codeString.length < 3) {
                    codeString = '0' + codeString;
                }

                const baseColumn = group.base;
                const baseName = baseColumn.name;

                const newColumn: Report2.Content.ColumnItem.Column = {
                    type: 'COLUMN',
                    id: baseColumn.id,
                    orderNumber: baseColumn.orderNumber,
                    name: {
                        defaultValue: `${baseName.defaultValue} (БПП ${codeString})`,
                        valueMap: {},
                    },
                    key: baseColumn.undistributedKey + '@budget-subprogram--' + code,
                    undistributedKey: baseColumn.undistributedKey,
                    unitName: baseColumn.unitName,
                    aggregation: baseColumn.aggregation,
                    width: baseColumn.width,
                    dataSource: baseColumn.dataSource,
                    colType: baseColumn.colType,
                    subprogDistEditable: baseColumn.subprogDistEditable,
                    detailedReportOnly: baseColumn.detailedReportOnly,
                    removeIfNoData: baseColumn.removeIfNoData,
                    fractionalDigitCount: baseColumn.fractionalDigitCount,
                    budgetSubprogramCode: code,
                    forTotal: baseColumn.forTotal,
                };

                // Добавление названий колонки
                Object.getOwnPropertyNames(baseName.valueMap).forEach((locale) => {
                    if (locale !== '__ob__') {
                        switch (locale) {
                            case 'kk':
                                newColumn.name.valueMap[locale] = `${baseName.valueMap[locale]} (БПП ${codeString})`;
                                break;
                            case 'en':
                                newColumn.name.valueMap[locale] = `${baseName.valueMap[locale]} (BSP ${codeString})`;
                                break;
                            default:
                                newColumn.name.valueMap[locale] = `${baseName.valueMap[locale]} (БПП ${codeString})`;
                                break;
                        }
                    }
                });

                // Добавление колонки
                group.slaves.set(code, newColumn);
                group.addedSlaves.set(code, newColumn);

                target.items.push(newColumn);
            }
        });
    });

    // Удаление колонок
    let i = 0;
    while (i < target.items.length) {
        const item = target.items[i];
        if ((item.type === 'COLUMN') && (item.subprogDistEditable)) {
            const code = item.budgetSubprogramCode;
            if ((code !== null) && (!codeSet.has(code))) {
                target.items.splice(i, 1);
            } else {
                i++;
            }
        } else {
            i++;
        }
    }

    // Сортировка колонок
    target.items.sort(columnSorter);
};

const scanForBudgetSubprograms = (items: Array<Report2.Content.ColumnItem>, collect: (budgetSubprogramCode: number) => void) => {
    for (const item of items) {
        if (item.type === 'COLUMN') {
            const code = item.budgetSubprogramCode;
            if (typeof code === 'number') {
                collect(code);
            }
        } else {
            scanForBudgetSubprograms(item.items, collect);
        }
    }
};
// endregion


@Component({
    components: {
        CollapsiblePane,
        FlatButton,
        NumberInput,
        SvgIcon,
    },
})
export default class ReportPage extends ComponentBase {
    constructor() {
        super('');
    }


    // region Утилиты
    public translates = translates;
    // endregion



    // region Lifecycle
    protected created() {
        // region Параметры страницы
        this.$watch('pageParamId', (pageParamId: number | null) => {
            if (pageParamId === null) {
                this.cancelReportLoading();
            } else {
                this.loadReport();
            }
        });
        this.$watch('pageParamYear', (pageParamYear: number | null) => {
            this.resetYear(pageParamYear ?? undefined);
        });
        // endregion

        // region Данные - отчет
        this.$watch('info', (/* info: Report2 | null */) => {
            this.resetBudgetSubprograms();
        });
        this.$watch('years', (/* years: Array<number> */) => {
            this.resetYear(this.pageParamYear ?? undefined);
        });
        this.$watch('year', (year: number | null) => {
            if (year !== null) {
                this.applyYear(year);
                this.resetSubprogram()
                this.resetBudgetSubprograms();
            }
        });
        // endregion
    }

    protected mounted() {
        if (this.pageParamId !== null) {
            this.loadReport();
        }
    }
    // endregion


    // region Параметры страницы
    public get pageParamId(): number | null {
        const idString = this.$route.params.id;
        const id = parseInt(idString);

        if (!(Number.isSafeInteger(id) && (String(id) === idString.trim()))) {
            return null;
        }

        return id;
    }

    public get pageParamYear(): number | null {
        const yearString = this.$route.params.year;
        const year = parseInt(yearString);

        if (!(Number.isSafeInteger(year) && (String(year) === yearString.trim()))) {
            return null;
        }

        return year;
    }
    // endregion


    // region Данные
    public get loading(): boolean {
        return (this.loadingReport || this.loadingBudgetSubprograms);
    }
    // endregion


    // region Данные - отчет
    public loadingReport = false;
    public reportLoadingId: symbol | null = null;
    public reportInfoAndContent: ReportInfoAndContent | null = null;
    public originalReportContent: Report2.Content | null = null;

    public get content(): Report2.Content | null {
        const reportInfoAndContent = this.reportInfoAndContent;
        if (reportInfoAndContent === null) {
            return null;
        }
        return reportInfoAndContent.content;
    }

    public get info(): Report2 | null {
        const reportInfoAndContent = this.reportInfoAndContent;
        if (reportInfoAndContent === null) {
            return null;
        }
        return reportInfoAndContent.info;
    }

    // noinspection JSUnusedGlobalSymbols
    public get contentChanged(): boolean {
        const reportContent = this.content;
        if (reportContent === null) {
            return false;
        }

        const originalReportContent = this.originalReportContent;
        if (originalReportContent === null) {
            return false;
        }

        return !reportContentsEqual(reportContent, originalReportContent);
    }

    public get version(): Version | null {
        return this.info?.version ?? null;
    }

    public get budgetVariant(): BudgetVariants | null {
        return this.info?.budgetVariantObject ?? null;
    }


    public get years(): Array<number> {
        const result: Array<number> = [];

        const year = this.budgetVariant?.year ?? null
        if (year !== null) {
            for (let i = 0; i < 3; i++) {
                result.push(year + i);
            }
        }
        return result;
    }

    public year: number | null = null;

    public get sheet(): Report2.Content.Sheet | null {
        const years = this.years;
        if (years.length === 0) {
            return null;
        }

        const year = this.year;
        if (year === null) {
            return null;
        }

        const sheetIndex = this.years.indexOf(year);
        if ((sheetIndex < 0) || (sheetIndex > 2)) {
            return null;
        }

        const content = this.content;
        if (content === null) {
            return null;
        }

        switch (sheetIndex) {
            case 0:
                return content.sheet1;
            case 1:
                return content.sheet2;
            case 2:
                return content.sheet3;
            default:
                return null;
        }
    }

    public get columnTreeData(): ColumnTreeData | null {
        const sheet = this.sheet;
        if (sheet === null) {
            return null;
        }

        return defineColumnTreeData(sheet.rootColumnGroups);
    }

    public get columns(): Array<Report2.Content.ColumnItem.Column> | null {
        const columnTreeData = this.columnTreeData;
        if (columnTreeData === null) {
            return null;
        }

        return columnTreeData.columns;
    }

    public get budgetSubprogramCodesFromColumns(): Set<number> {
        const result = new Set<number>();

        const columns = this.columns;
        if (columns !== null) {
            for (const column of columns) {
                const budgetSubprogramCode = column.budgetSubprogramCode;
                if (budgetSubprogramCode !== null) {
                    result.add(budgetSubprogramCode);
                }
            }
        }

        return result;
    }

    public get distColumnGroupMap(): Map<string, DistColumnGroup> | null {
        const columns = this.columns;
        if (columns === null) {
            return null;
        } else {
            return defineDistColumnGroupMap(columns);
        }
    }

    public get distColumnGroups(): Array<DistColumnGroup> | null {
        const distColumnGroupMap = this.distColumnGroupMap;
        if (distColumnGroupMap === null) {
            return null;
        }

        return [...distColumnGroupMap.values()];
    }

    public get rows(): Array<Report2.Content.Row> | null {
        const sheet = this.sheet;
        if (sheet === null) {
            return null;
        }
        return sheet.rows;
    }


    private cancelReportLoading() {
        this.reportLoadingId = null;
    }

    private loadReport() {
        if (this.loadingReport) {
            console.warn('Another report loading is running - its results will be ignored');
        }

        const reportLoadingId = Symbol(`report-loading-${Date.now()}`);
        this.reportLoadingId = reportLoadingId;
        this.reportInfoAndContent = null;

        this.loadingReport = false;

        const pageParamId = this.pageParamId;
        if (pageParamId === null) {
            console.error('Cannot load report - report ID is null');
            return;
        }

        this.loadingReport = true;
        this.request<ReportInfoAndContent>(
            { url: `/api/budget/staffing_table/report-v2/info-and-content?id=${pageParamId}` },
            (data) => {
                if (this.reportLoadingId === reportLoadingId) {
                    this.reportInfoAndContent = data;
                    this.originalReportContent = copyReportContent(data.content);
                }
            },
            () => (translates.error.cannotLoadReportContent(pageParamId)),
            () => {
                if (this.reportLoadingId === reportLoadingId) {
                    this.reportLoadingId = null;
                    this.loadingReport = false;
                }
            },
        );
    }


    public subprogramList: SheetSubprogram[] = [];

    get sheetSubprograms(): SheetSubprogram[] {
        return this.subprogramList;
    }

    resetSubprogram() { this.subprogramList = [] }

    get numberSheet(): number | null {
        const { years, year } = this

        if (!years.length || year === null) {
            return null;
        }
        const sheetIndex = years.indexOf(year);

        return (sheetIndex >= 0 && sheetIndex <= 2) ? sheetIndex : null;
    }


    generateLinksReports() {
        const reportId = this.info?.id ?? null;

        if (reportId === null){
            return;
        }

        this.request<SheetSubprogram[]>({
            url: `/api/budget/staffing_table/report-v2/subprograms-sheet`,
            method: 'GET',
            params: { report_id: reportId },
        },
            (data) => {
            if (data.isEmpty) {
                this.toast('warning', translates.infoMessage.infoWarning, translates.infoMessage.emptySubprogramLinks(reportId))
            }
            this.subprogramList = data },

            () => (translates.error.cannotLoadReportContent(reportId)),
        )
    }

    generateSubprogramReport(subprogram: SheetSubprogram) {
        const locale = i18n.locale
        const paramMap: Array<[string, unknown]> = [
            ['report-id', subprogram.reportId],
            ['sub-program', subprogram.subprogram],
            ['locale', locale]
        ];

        const url = this.prepareUrl(
            '/api/budget/staffing_table/report-v2/subprograms-report', paramMap);

        this.openUrl(url, '_blank', false);
    }


    public saveReport() {
        if (this.loadingReport) {
            console.warn('Another report loading is running - its results will be ignored');
        }

        const reportLoadingId = Symbol(`report-loading-${Date.now()}`);
        this.reportLoadingId = reportLoadingId;

        this.loadingReport = false;

        const content = this.content;
        if (content === null) {
            console.error('Cannot save report - report content is null');
            return;
        }

        const pageParamId = this.pageParamId;
        if (pageParamId === null) {
            console.error('Cannot save report - report ID is null');
            return;
        }

        const budgetSubprogramCodeSet = new Set<number>();
        const budgetSubprogramCodeCollect = (budgetSubprogramCode: number): void => {
            budgetSubprogramCodeSet.add(budgetSubprogramCode);
        };

        scanForBudgetSubprograms(content.sheet1.rootColumnGroups, budgetSubprogramCodeCollect);
        const firstYearBudgetSubprograms = [...budgetSubprogramCodeSet].sort();
        budgetSubprogramCodeSet.clear();

        scanForBudgetSubprograms(content.sheet2.rootColumnGroups, budgetSubprogramCodeCollect);
        const secondYearBudgetSubprograms = [...budgetSubprogramCodeSet].sort();
        budgetSubprogramCodeSet.clear();

        scanForBudgetSubprograms(content.sheet3.rootColumnGroups, budgetSubprogramCodeCollect);
        const thirdYearBudgetSubprograms = [...budgetSubprogramCodeSet].sort();

        const firstYearHasErrors = sheetHasErrors(content.sheet1);
        const secondYearHasErrors = sheetHasErrors(content.sheet2);
        const thirdYearHasErrors = sheetHasErrors(content.sheet3);

        this.request(
            {
                url: `/api/budget/staffing_table/report-v2/content/${pageParamId}`,
                method: 'POST',
                data: content,
                headers: {
                    'csi--year-1--budget-subprograms': JSON.stringify(firstYearBudgetSubprograms),
                    'csi--year-2--budget-subprograms': JSON.stringify(secondYearBudgetSubprograms),
                    'csi--year-3--budget-subprograms': JSON.stringify(thirdYearBudgetSubprograms),
                    'csi--year-1--has-errors': JSON.stringify(firstYearHasErrors),
                    'csi--year-2--has-errors': JSON.stringify(secondYearHasErrors),
                    'csi--year-3--has-errors': JSON.stringify(thirdYearHasErrors),
                },
            },
            () => { this.toast('success', '', translates.reportSaved); },
            () => (translates.error.cannotSaveReport),
            () => {
                if (this.reportLoadingId === reportLoadingId) {
                    this.reportLoadingId = null;
                    this.loadingReport = false;
                }
            },
        );
    }

    private resetYear(requiredYear?: number) {
        const years = this.years;
        if (years.length > 0) {
            let year: number;
            if ((requiredYear !== undefined) && (years.includes(requiredYear))) {
                year = requiredYear;
            } else {
                year = years[0];
            }
            this.applyYear(year);
        } else {
            this.year = null;
        }
    }

    private applyYearTimeoutId: number | null = null;

    private applyYear(value: number) {
        if (this.year !== value) {
            this.year = value;
        }

        if (this.pageParamYear !== value) {
            const pageParamId = this.pageParamId;
            if (pageParamId !== null) {
                const newLocation: RawLocation = { path: `/staffing-table/report-v2/${pageParamId}/${value}` };
                const resolved = this.$router.resolve(newLocation);
                if (resolved.href !== this.$router.currentRoute.fullPath) {
                    const oldTimeoutId = this.applyYearTimeoutId;
                    if (oldTimeoutId !== null) {
                        clearTimeout(oldTimeoutId);
                    }

                    const newTimeoutId = setTimeout(() => {
                        if (this.applyYearTimeoutId === newTimeoutId) {
                            this.applyYearTimeoutId = null;
                            if (resolved.href !== this.$router.currentRoute.fullPath) {
                                if (this.pageParamYear === null) {
                                    this.$router.replace(resolved.location);
                                } else {
                                    this.$router.push(resolved.location);
                                }
                            }
                        }
                    });
                    this.applyYearTimeoutId = newTimeoutId;
                }
            }
        }
    }


    public getColumnItemName(columnItem: Report2.Content.ColumnItem): string {
        return columnItem.name.valueMap[i18n.locale] ?? columnItem.name.defaultValue;
    }

    public isColumnDistributable(column: Report2.Content.ColumnItem.Column): boolean {
        return (column.subprogDistEditable);
    }

    public isColumnEditable(column: Report2.Content.ColumnItem.Column): boolean {
        return (column.subprogDistEditable && (column.budgetSubprogramCode !== null));
    }

    public getColumnWidth(column: Report2.Content.ColumnItem.Column): number {
        if (this.isColumnEditable(column)) {
            return Math.max(column.width, 180);
        } else {
            return column.width;
        }
    }

    public get hasErrors(): boolean {
        const rows = this.rows;
        if (rows !== null) {
            for (const row of rows) {
                if (this.rowHasError_(row)) {
                    return true;
                }
            }
        }

        return false;
    }


    public reportInfoExpanded = false;
    // endregion


    // region Данные - бюджетные подпрограммы
    public loadingBudgetSubprograms = false;
    public budgetSubprogramLoadingId: symbol | null = null;
    public loadedBudgetSubprograms: Array<Dict.EbkFunc> = [];

    public get loadedBudgetSubprogramMap(): Map<number, Dict.EbkFunc> {
        const result = new Map<number, Dict.EbkFunc>();

        for (const budgetSubprogram of this.loadedBudgetSubprograms) {
            const code = budgetSubprogram.ppr;
            if (code !== null) {
                result.set(code, budgetSubprogram);
            }
        }

        return result;
    }

    public get allBudgetSubprogramWrapMap(): Map<number, BudgetSubprogramWrap> {
        const result = new Map<number, BudgetSubprogramWrap>();

        for (const entry of this.loadedBudgetSubprogramMap) {
            const code = entry[0];
            const item = entry[1];
            const wrap: BudgetSubprogramWrap = { code, item };
            result.set(code, wrap);
        }

        for (const code of this.budgetSubprogramCodesFromColumns) {
            if (!result.has(code)) {
                const wrap: BudgetSubprogramWrap = { code, item: null };
                result.set(code, wrap);
            }
        }

        return result;
    }

    public get usedBudgetSubprogramWrapMap(): Map<number, BudgetSubprogramWrap> {
        const sourceMap = this.allBudgetSubprogramWrapMap;
        const filterCodes = this.budgetSubprogramCodesFromColumns;
        return new Map([...sourceMap.entries()].filter((entry) => (filterCodes.has(entry[0]))));
    }

    public get usedBudgetSubprogramWraps(): Array<BudgetSubprogramWrap> {
        return [...this.usedBudgetSubprogramWrapMap.values()].sort(budgetSubprogramWrapSorter);
    }

    public get availableBudgetSubprogramWapMap(): Map<number, BudgetSubprogramWrap> {
        const sourceMap = this.allBudgetSubprogramWrapMap;
        const filterCodes = this.budgetSubprogramCodesFromColumns;
        return new Map([...sourceMap.entries()].filter((entry) => (!filterCodes.has(entry[0]))));
    }

    public get availableBudgetSubprogramWraps(): Array<BudgetSubprogramWrap> {
        return [...this.availableBudgetSubprogramWapMap.values()].sort(budgetSubprogramWrapSorter);
    }

    public resetBudgetSubprograms() {
        this.loadingBudgetSubprograms = false;
        this.budgetSubprogramLoadingId = null;
        this.loadedBudgetSubprograms = [];
        if ((this.info !== null) && (this.year !== null)) {
            this.reloadBudgetSubprograms();
        }
    }

    public reloadBudgetSubprograms() {
        if (this.budgetSubprogramLoadingId !== null) {
            this.budgetSubprogramLoadingId = null;
            console.warn('Previous budget subprograms loading cancelled, its results will be ignored');
        }

        this.loadingBudgetSubprograms = false;
        this.loadedBudgetSubprograms = [];

        const info = this.info;
        if (info === null) {
            console.error('Cannot reload budget subprograms - report info is NULL');
            return;
        }

        const year = this.year;
        if (year === null) {
            console.error('Cannot reload budget subprograms - selected year is NULL');
            return;
        }

        const funcGroupCode = info.funcGroup;
        const funcSubgroupCode = info.funcSubgroup;
        const abpCode = info.abp;
        const budgetProgramCode = info.budgetProgram;
        const date = new Date(year, 0, 1, 0, 0, 0, 0).getTime();

        const id = Symbol('loading-budget-subprograms');
        this.budgetSubprogramLoadingId = id;
        this.loadingBudgetSubprograms = true;
        const url = this.prepareUrl(
            '/api/budget/staffing_table/report/budget-subprograms',
            [
                ['func-group-code', funcGroupCode],
                ['func-subgroup-code', funcSubgroupCode],
                ['abp-code', abpCode],
                ['budget-program-code', budgetProgramCode],
                ['date', date],
            ],
        );
        this.request<Array<Dict.EbkFunc>>(
            { url },
            (data) => {
                if (id === this.budgetSubprogramLoadingId) {
                    this.loadedBudgetSubprograms = [...data].sort((item1, item2) => {
                        const code1 = item1.ppr ?? 0;
                        const code2 = item2.ppr ?? 0;
                        return (code1 - code2);
                    });
                }
            },
            () => (translates.error.cannotLoadBudgetSubprograms),
            () => {
                if (id === this.budgetSubprogramLoadingId) {
                    this.budgetSubprogramLoadingId = null;
                    this.loadingBudgetSubprograms = false;
                }
            },
        );
    }


    public getBudgetSubprogramTitle(item: BudgetSubprogramWrap): string {
        const budgetSubprogram: Dict.EbkFunc | null = item.item;

        let code: string = String(item.code);
        while (code.length < 3) {
            code = '0' + code;
        }

        let name: string | null;
        if (budgetSubprogram === null) {
            name = null;
        } else {
            name = i18n.choose(
                () => (budgetSubprogram.nameRu),
                () => (budgetSubprogram.nameKk ?? budgetSubprogram.nameRu),
                () => (budgetSubprogram.nameRu),
            )();
        }

        if (name === null) {
            return code;
        } else {
            return `${code} - ${name}`;
        }
    }
    // endregion


    // region Данные - бюджетные подпрограммы - модальное окно
    public modalAvailableBudgetSubprograms: Array<BudgetSubprogramWrap> = [];
    public modalUsedBudgetSubprograms: Array<BudgetSubprogramWrap> = [];
    public budgetSubprogramsModalVisible = false;

    public showBudgetSubprogramsModal() {
        this.modalAvailableBudgetSubprograms = [...this.availableBudgetSubprogramWraps];
        this.modalUsedBudgetSubprograms = [...this.usedBudgetSubprogramWraps];
        this.budgetSubprogramsModalVisible = true;
    }

    public makeModalBudgetSubprogramUsed(item: BudgetSubprogramWrap) {
        const available = [...this.modalAvailableBudgetSubprograms].filter((testedItem) => (testedItem.code !== item.code));
        const used = [...this.modalUsedBudgetSubprograms];
        used.push(item);
        used.sort(budgetSubprogramWrapSorter);
        this.modalAvailableBudgetSubprograms = available;
        this.modalUsedBudgetSubprograms = used;
    }

    public removeModalBudgetSubprogramUsage(item: BudgetSubprogramWrap) {
        const available = [...this.modalAvailableBudgetSubprograms];
        const used = [...this.modalUsedBudgetSubprograms].filter((testedItem) => (testedItem.code !== item.code));
        available.push(item);
        available.sort(budgetSubprogramWrapSorter);
        this.modalAvailableBudgetSubprograms = available;
        this.modalUsedBudgetSubprograms = used;
    }

    public applyBudgetSubprogramsFromModal() {
        const codes = [...this.modalUsedBudgetSubprograms.map((item) => (item.code))].sort();
        const codeSet = new Set<number>(codes);

        const sheet = this.sheet;
        if (sheet !== null) {
            const outputDistColumnGroupMap = new Map<string, DistColumnGroupForRebuilding>();

            // Перестройка колонок
            sheet.rootColumnGroups.forEach((group) => {
                rebuildColumnGroupBySubprogramCodes(codes, codeSet, group, outputDistColumnGroupMap);
            });

            // Перестройка строк
            sheet.rows.forEach((row) => {
                outputDistColumnGroupMap.forEach((group) => {
                    group.removedSlaves.forEach((removedColumn) => {
                        delete row.valueMap[removedColumn.key];
                    });
                });
            });
        }
    }
    // endregion


    // region Данные - строки
    public getReadonlyNonDistributableValue(row: Report2.Content.Row, column: Report2.Content.ColumnItem.Column): string {
        const describedValue = (row.valueMap[column.key] as unknown as (Report2.Content.DescribedValue | undefined));
        if (describedValue === undefined) {
            return '';
        } else {
            const value = describedValue.value;
            if (value === null) {
                return '';
            }

            switch (value.type) {
                case 'STRING':
                    return i18n.choose(
                        () => value.value.valueMap['ru'] ?? value.value.defaultValue,
                        () => value.value.valueMap['kk'] ?? value.value.defaultValue,
                        () => value.value.valueMap['en'] ?? value.value.defaultValue,
                    )();
                case 'NUMBER':
                    return String(value.value);
                default:
                    return '';
            }
        }
    }

    public getReadonlyDistributableRemainder(row: Report2.Content.Row, column: Report2.Content.ColumnItem.Column): number {
        const distColumnGroupMap = this.distColumnGroupMap;
        if (distColumnGroupMap === null) {
            return 0;
        }

        const distColumnGroup = (distColumnGroupMap.get(column.undistributedKey) as unknown as (DistColumnGroup | undefined));
        if (distColumnGroup === undefined) {
            return 0;
        }

        return getRowDistributableRemainder(distColumnGroup, row);
    }

    public getReadonlyDistributableValue(row: Report2.Content.Row, column: Report2.Content.ColumnItem.Column): string {
        const describedValue = (row.valueMap[column.key] as unknown as (Report2.Content.DescribedValue | undefined));
        if (describedValue === undefined) {
            return '';
        } else {
            const value = describedValue.value;
            if ((value !== null) && (value.type === 'NUMBER')) {
                if (column.subprogDistEditable) {
                    const distColumnGroups = this.distColumnGroupMap;
                    if (distColumnGroups !== null) {
                        const group = distColumnGroups.get(column.undistributedKey);
                        // noinspection JSIncompatibleTypesComparison
                        if (group !== undefined) {
                            let calculationDescription = String(value.value);

                            let remaining = new DecimalJs(value.value);
                            group.slaves.forEach((slaveColumn) => {
                                const slaveValue = this.getEditableValue(row, slaveColumn);
                                remaining = remaining.minus(slaveValue);
                                calculationDescription += ' - ' + slaveValue;
                            });

                            if (remaining.toNumber() === 0) {
                                return String(value.value);
                            } else {
                                return i18n.choose(
                                    () => `${value.value} \r\n [ Нераспределенный остаток: ${calculationDescription} = ${remaining} ]`,
                                    () => `${value.value} \r\n [ Нераспределенный остаток: ${calculationDescription} = ${remaining} ]`,
                                    () => `${value.value} \r\n [ Undistributed balance: ${calculationDescription} = ${remaining} ]`,
                                )();
                            }
                        }
                    }
                }

                return String(value.value);
            }
        }

        return '';
    }

    public getEditableValue(row: Report2.Content.Row, column: Report2.Content.ColumnItem.Column): number {
        return getRowDistributionEditableValue(row, column);
    }

    public setEditableValue(row: Report2.Content.Row, column: Report2.Content.ColumnItem.Column, value: number | null) {
        const oldDescribedValue = (row.valueMap[column.key] as unknown as (Report2.Content.DescribedValue | undefined));
        if (oldDescribedValue === undefined) {
            const describedValue: Report2.Content.DescribedValue = {
                value: {
                    type: 'NUMBER',
                    value: value ?? 0,
                },
                descriptionListMap: {},
            };
            Vue.set(row.valueMap, column.key, describedValue);
        } else {
            Vue.set(oldDescribedValue, 'value', { type: 'NUMBER', value: value ?? 0 });
        }
    }

    public rowHasError_(row: Report2.Content.Row): boolean {
        const distColumnGroups = this.distColumnGroups;
        if (distColumnGroups === null) {
            return false;
        }
        return rowHasError(distColumnGroups, row);
    }

    public transferSumInAllRows(column: Report2.Content.ColumnItem.Column) {
        const rows = this.rows;
        if (rows === null) {
            return;
        }

        rows.forEach((row) => {
            this.transferSumInRow(column, row);
        });
    }

    public transferSumInRow(column: Report2.Content.ColumnItem.Column, row: Report2.Content.Row) {
        const currentValue = this.getEditableValue(row, column);
        const remainder = this.getReadonlyDistributableRemainder(row, column);
        if (remainder !== 0) {
            const newValue = new DecimalJs(currentValue).plus(remainder).toNumber();
            this.setEditableValue(row, column, newValue);
        }
    }
    // endregion


    // region Данные - строка "Итого"
    public get totalRow(): Record<string, number | undefined> {
        const totalValues = new Map<string, DecimalJs>();

        this.rows?.forEach((row) => {
            const valueMap = row.valueMap;
            Object
                .getOwnPropertyNames(valueMap)
                .filter((key) => (key != "__ob__"))
                .forEach((key) => {
                    const rowDescribedValue = (row.valueMap[key] as unknown as (Report2.Content.DescribedValue | undefined));
                    if (rowDescribedValue !== undefined) {
                        const rowValue = rowDescribedValue.value;
                        if ((rowValue !== null) && (rowValue.type === 'NUMBER')) {
                            const savedTotalValue = totalValues.get(key);
                            const newTotalValue = (savedTotalValue ?? new DecimalJs(0)).plus(rowValue.value);
                            totalValues.set(key, newTotalValue);
                        }
                    }
                });
        });

        const result: Record<string, number | undefined> = {};
        totalValues.forEach((value, key) => {
            result[key] = value.toNumber();
        });
        return result;
    }
    // endregion


    // region Данные - ячейки
    public getCellVariant(column: Report2.Content.ColumnItem.Column, row: Report2.Content.Row): Comp.ColorVariant | '' {
        /*
        Исходная реализация

        Распределяемая колонка - isColumnDistributable(column)
            Редактируемая колонка - isColumnEditable(column)
                primary
            Не редактируемая колонка - v-else
                getReadonlyDistributableRemainder(row, column) !== 0 ? 'danger' : (rowHasError_(row) ? 'warning' : 'success')
        Не распределяемая колонка - v-else
            rowHasError_(row) ? 'warning' : 'success'
         */

        /*
        http://192.168.0.146/issues/6637
        5. Цветовая индикация при распределении: выделять красным ячейки, по которым еще не сделано распределение, и строку с такой ячейкой выделять желтым
         */

        if (this.rowHasError_(row)) {
            if (this.isColumnEditable(column)) {
                if (this.getReadonlyDistributableRemainder(row, column) > 0) {
                    return 'danger';
                } else {
                    return 'warning';
                }
            } else {
                return 'warning';
            }
        } else {
            return 'success';
        }
    }
    // endregion
}
